import { AnimationKeyFrames } from "@ionic/angular";

export interface sendCodeI {
  email: any;
}

export interface QuestionAnswerI {
  article_answer: any;
  student_option: any;
  student_tests_answers_id: any;
  question_id: any;
  answer_attachments: attachmentsI[];
  questions_sort_words: number[];
  questions_complete_it: questioCompleteItem[];
  questions_complete_it_writing_auto_correct: questioCompleteItem[];
  questions_complete_it_writing_manual_correct: questioCompleteItem[];

  questions_match_items: questionMatchItem[];
  answerd?: boolean;
}
export interface questionMatchItem {
  id: any;
  student_answer_item: any;
}
export interface questioCompleteItem {
  sub_question_id: any;
  answer: any;
}

export interface svgMatchLineI {
  startId: any;
  endId: any;
  startPointx: any;
  startPointy: any;
  endPointx: any;
  endPointy: any;
  status?: string;
}

export interface editProfileI {
  full_name: any;
  mobile: any;
  country_code: any;
  country_id: any;
  stage_id: any;
  grade_id: any;
  specialize_id: any;
  teacher_subjects: any;
  students_for_parent: any;
  img: any;
  description:any;
  file:any;
  upload_type:any
  teacher_upload_type?:string;
  teacher_desc?:any;
  teacher_file?:any;
  field_of_study_id?:string;
  field_of_study?:string;
}

export interface signUpI {
  full_name: any;
  country_code: any;
  email: any;
  mobile: any;
  password: any;
  confirm_password: any;
  user_type: any;
  country_id: any;
  stage_id: any;
  grade_id: any;
  specialize_id: any;
  teacher_subjects: any;
  students_for_parent: any;
  acceptCondition: boolean;
  field_of_study_id?:string;
  subject_id?:string;
}

export interface RelatedAccount {
  full_name: any;
  email: any;
  user_type: any;
  stage_id: any;
  grade_id: any;
  pin: any;
  specialize_id: any;
  teacher_subjects: any;
  students_for_parent: any;
  // pin: any,
}

export interface loginI {
  email: any;
  password: any;
}

export interface resetPasswordI {
  email: any;
  code: any;
  new_password: any;
  confirm_password: any;
}
export interface editMobileI {
  email: any;
  code: any;
  type: any;
}
export interface confirmMobileI {
  mobile: any;
  country_code: any;
  code: any;
  type: any;
  country_id: any;
}

export interface TestAddingI {
  my_test_id: any;
  name: any;
  description: any;
  questions: any;
  subject_id: any;
  unit_id: any;
  lesson_id: any;
  type: any;
  deleted_questions_ids?: string;
  question_by_code?: boolean;
}

export interface TestCustomAddingI {
  my_test_id: any;
  name: any;
  description: any;
  subject_id: any;
  unit_id: any;
  lesson_id: any;
  type: any;
  question?: questionAddingI;
  available_to_visitor?:any;
}

export interface addQuestionI {
  my_test_id: any;
  question: questionAddingI;
}

export interface qustionByCodeI {
  id: any;
  error: any;
}

export interface questionAddingI {
  removeLoader?: boolean;
  id?: any;
  title: any;
  extra_desc: any;
  answer_form: any,
  size_article_q: any,
  type: any;
  source: any;
  degree: any;
  note: any;
  note_attachments: attachmentsI[];
  cash_note_attachment?: fileUploadI;
  test_question_options: questionOptionsAddingI[];
  test_questions_paragraphs?: questionAddingI[];
  question_attachments: attachmentsI[];
  cash_question_attachment?: fileUploadI;
  test_question_sort_words: sortWordsI[];
  test_question_match_options: MatchItI[];
  test_questions_complete_it_sub: CompleteItSubItemI[];
  error: any;
}

export interface CompleteItSubItemI {
  title_show: string;
  title: string;
  degree: number;
  test_questions_complete_it_words: CompleteITWordI[];
  note: any;
  note_attachments: attachmentsI[];
  cash_note_attachment?: fileUploadI;
  error: any;
}
export interface CompleteITWordI {
  id: number | null;
  title: string | null;
  word_index: string | null;
}

export interface sortWordsI {
  title: string;
  word_index: number;
  error: any;
}
export interface MatchItI {
  title1: string;
  title2: string;
  image1: string;
  image2: string;
  righte_index1: string;
  upload_image1: fileUploadI;

  upload_image2: fileUploadI;

  error: any;
}

export interface questionOptionsAddingI {
  title: any;
  status: any;

  option_attachments: attachmentsI[];

  option_subject_event_attachments?: any;

  cash_option_attachment?: fileUploadI;

  error: any;
}

export interface attachmentsI {
  attachment_type: any;
  file: any;
}

export interface groupI {
  group_id: any;
  name: any;
  description: any;
  image: any;
}

export interface addPostI {
  post_id: any;
  group_id: any;
  content: any;
  attachments: attachmentsI[];
  can_download_attachements: boolean;
}
export interface fileUploadI  {
  id?: string;
  FileN?: string;
  name: any;
  url: any;
  attachment_type?: string;
  progress: number;
  loader: boolean;
  error_upload: any;
  fileTransfer?: any;
}

export interface ShareTestI {
  message?: string; // not req
  test_id: any;
  students: any[]; // at lest one
  groups: any[]; // at lest one
  target: string; // students || groups
  test_duration: any;
  scheduled_date: any;
  scheduled_test: boolean;
  is_duration: boolean;
  show_result_to_student: boolean;
  code?:any;
  random_order?:boolean;
}
export interface SolveTestI {
  student_tests_answers_id: string; // not req
  questions: SolveTestQustuionI[];
}

export interface SolveTestQustuionI {
  question_id: string;
  answer_degree: any;
}
export interface renewSubscriptionI {
  wallet_phone_number: string;
  email: string;
  payment_type: string;
  subscription_id: any;
  semester: string;
  pageType?: string;
  meeting?: string;
  course_id?:string;
  course_cost?:string;
}

export interface StartShareTestI {
  message: string;
  test_id: any;
}

export interface SetStudentAnswersI {
  test_id: string;
  share_id: string;
  student_id: string;
  retunren_from_api_student_options: any;
}

export enum USERTYPES {
  teacher = "teacher",
  student = "student",
  parent = "parent",
}

export enum PaymentMethod {
  paymob_wallet = "paymob_wallet",
  fawry = "fawry",
  vodafone= "vodafone_cash"
}
export interface MeetI {
  meeting_serial: string;
  is_paid: boolean;
  title: string;
  price: any;
  is_scheduled: boolean;
  scheduled_date: Date;
}

export interface StudnetSettings {
  answer_question_sound: boolean;
}

export interface gradeResponse {
  id: string;
  parent: string;
  date_added: string;
  name: string;
}
