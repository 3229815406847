import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { DataStoreService } from './data-store.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
// declare var cordova: any;
@Injectable()


export class HttpCustomService {
  //server

  public env = environment;
  API_URL_CONST: string;
  API_URL: string;

  // new for event and listen event
  constructor(
    public http: HttpClient,

  ) {
    this.API_URL = this.env.api_link + this.env.api_version;
    this.API_URL_CONST = this.env.api_link + "api-const-new/";
  }

  get<T>(endpoint: string, params?: any):Observable<T> {
    let httpParams: HttpParams = new HttpParams({});
    let httpHeaders: HttpHeaders = new HttpHeaders({})

    httpParams = httpParams.set('time', new Date().toISOString())//to disable cash
    if (params) {
      for (let param in params) {
        httpParams = httpParams.set(param, params[param])
      }
    }
    return this.http.get<T>(this.API_URL + endpoint, { params: httpParams, headers: httpHeaders })
  }

  post(endpoint: string, body: any, params?: any, headers?: any) {
    let httpParams: HttpParams = new HttpParams({});
    let httpHeaders: HttpHeaders = new HttpHeaders({})
    console.log(httpHeaders)

    httpParams = httpParams.set('time', new Date().toISOString())//to disable cash
    if (httpParams && params) {

      for (let param in params) {
        httpParams = httpParams.set(param, params[param])

      }
    }
    if (httpHeaders && headers) {
      for (let header in headers) {
        httpHeaders = httpHeaders.set(header, headers[header])
      }
    }
    return this.http.post<any>(this.API_URL + endpoint, body, { params: httpParams, headers: httpHeaders })
  }

  delete(endpoint: string, params?: any) {

    let httpParams: HttpParams = new HttpParams({});
    let httpHeaders: HttpHeaders = new HttpHeaders({})
    httpParams = httpParams.set('time', new Date().toISOString())//to disable cash
    if (httpParams && params) {

      for (let param in params) {
        httpParams = httpParams.set(param, params[param])

      }
    }
    return this.http.delete<any>(this.API_URL + endpoint, { params: httpParams, headers: httpHeaders })
  }

  getConist(endpoint: string, params?: any) {
    let httpParams: HttpParams = new HttpParams({});
    let httpHeaders: HttpHeaders = new HttpHeaders({})
    httpParams = httpParams.set('time', new Date().toISOString())//to disable casha
    if (params) {
      for (let param in params) {
        httpParams = httpParams.set(param, params[param])
      }
    }
    return this.http.get<any>(this.API_URL_CONST + endpoint, { params: httpParams, headers: httpHeaders })
  }

}


