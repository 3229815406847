import { BehaviorSubject } from "rxjs";

export class EventService {
    private dataObserved = new BehaviorSubject<any>('');
    currentEvent = this.dataObserved.asObservable();
    constructo(){}
    
    publish(param):void {
      this.dataObserved.next(param);
    }
}