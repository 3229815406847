import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';


// declare var cordova: any;
@Injectable()

export class OpenExternalUtilfuns {

  constructor(
    public appAvailability: AppAvailability,
    public platform: Platform,
  ) {
  }
  public GetPlatform() {
    return this.platform.is('ios') ? 'ios' : 'android';
  }


  public openExternalUrl(url, type, message?: any) {
    // console.log('url in provider', url, 'type in provider', type);
    let scheme = {
      name: '',
      appUrl: '',
      browserUrl: ''
    };
    if (this.GetPlatform() == 'ios') {

      if (type == 'twitter') {
        scheme = {
          name: 'twitter://',
          appUrl: 'twitter://user?screen_name=',
          browserUrl: 'https://twitter.com/'
        };

      }
      // else if (type == 'facebook') {
      //   let appUrl = 'fb://' + facepage + '/'
      //   scheme = {
      //     name: 'fb://',
      //     appUrl: appUrl,
      //     browserUrl: 'https://www.facebook.com/'
      //   };
      // }
      else if (type == 'LinkedIn') {

        scheme = {
          name: 'linkedin://',
          appUrl: 'linkedin://profile/',
          browserUrl: 'https://www.linkedin.com/in/'
        };
      } else if (type == 'gplus') {

        scheme = {
          name: 'gplus://',
          appUrl: 'gplus://plus.google.com/u/0/',
          browserUrl: 'https://plus.google.com/'
        };
      }
      else if (type == 'instagram') {
        scheme = {
          name: 'instagram://',
          appUrl: 'instagram://user?username=',
          browserUrl: 'https://www.instagram.com/'
        };
      }
      else if (type == 'map') {

        scheme = {
          name: 'comgooglemapsurl://',
          appUrl: 'comgooglemapsurl://?center=',
          browserUrl: 'http://maps.apple.com/?ll='
        };
      }
      else if (type == 'youtube') {

        scheme = {
          name: 'youtube://',
          appUrl: 'youtube://',
          browserUrl: 'https://www.youtube.com/watch?v='
        };
      }
      else if (type == 'whatsapp') {

        scheme = {
          name: 'whatsapp://',
          appUrl: 'whatsapp://send?text=' + message + '&phone=',
          browserUrl: 'https://api.whatsapp.com/send/?text=' + message + '&phone='
        };
      }
      else if (type == 'browser') {
        // console.log('typeeee', type)
        scheme = {
          name: 'chrome://',
          appUrl: 'chrome://',
          browserUrl: ''
        };
      }
    } else if (this.GetPlatform() == 'android') {

      if (type == 'twitter') {
        scheme = {
          name: 'com.twitter.android',
          appUrl: 'twitter://user?screen_name=',
          browserUrl: 'https://twitter.com/'
        };

      }
      // else if (type == 'facebook') {
      //   let appUrl = 'fb://' + facepage + '/'
      //   scheme = {
      //     name: 'com.facebook.katana',
      //     appUrl: appUrl,
      //     browserUrl: 'https://www.facebook.com/'
      //   };
      // } 
      else if (type == 'LinkedIn') {

        scheme = {
          name: 'linkedin://',
          appUrl: 'linkedin://profile/',
          browserUrl: 'https://www.linkedin.com/in/'
        };
      } else if (type == 'gplus') {

        scheme = {
          name: 'com.google.android.apps.plus',
          appUrl: 'gplus://plus.google.com/u/0/',
          browserUrl: 'https://plus.google.com/'
        };
      }
      else if (type == 'instagram') {
        scheme = {
          name: 'instagram://',
          appUrl: 'instagram://user?username=',
          browserUrl: 'https://www.instagram.com/'
        };
      }
      else if (type == 'map') {

        scheme = {
          name: 'com.google.android.apps.maps',
          appUrl: 'geo:',
          browserUrl: 'https://www.google.com/maps/search/?api=1&query='
        };
      }
      else if (type == 'youtube') {

        scheme = {
          name: 'youtube://',
          appUrl: 'youtube://',
          browserUrl: 'https://www.youtube.com/watch?v='
        };
      }
      else if (type == 'whatsapp') {

        scheme = {
          name: 'com.whatsapp',
          appUrl: 'whatsapp://send?text=' + message + '&phone=',
          browserUrl: 'https://api.whatsapp.com/send/?text=' + message + '&phone='
        };

      } else if (type == 'browser') {

        scheme = {
          name: 'chrome://',
          appUrl: 'chrome://',
          browserUrl: ''
        };
      }
    }
    this.appAvailability.check(scheme.name).then(
      () => { // success callback
        if (type == 'map') {
          // console.log('url=',scheme.appUrl + url.longANDlati+'?q='+url.longANDlati)
          if (!window.open(scheme.appUrl + url.longANDlati + '?q=' + url.longANDlati, '_system', 'location=no')) {
            window.location.href = scheme.appUrl + url.longANDlati + '?q=' + url.longANDlati
          }
        } else {


          if (!window.open(scheme.appUrl + url, '_system', 'location=no')) {
            window.location.href = scheme.appUrl + url
          }

        }
      },
      () => { // error callback
        //console.log('type',type);
        //console.log('this.isIOS()',this.isIOS());
        if (type == 'map') {
          // console.log('url==',scheme.browserUrl + url.longANDlati+'&q='+url.user_name);
          if (!window.open(scheme.browserUrl + url.longANDlati + '?q=' + url.longANDlati, '_system', 'location=no')) {
            window.location.href = scheme.browserUrl + url.longANDlati + '?q=' + url.longANDlati
          }

        } else if (type == 'whatsapp' && this.GetPlatform() == 'ios') {


          if (!window.open(scheme.appUrl + url, '_system', 'location=no')) {
            window.location.href = scheme.appUrl + url
          }

        }
        else {
          if (!window.open(scheme.browserUrl + url, '_system', 'location=no')) {
            window.location.href = scheme.browserUrl + url
          }
        }
      }
    )
  }



}


