import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DateTimePickerPage } from './date-time-picker.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule
  ,
  TranslateModule],
  declarations: [DateTimePickerPage],
})
export class DateTimePickerPageModule { }
