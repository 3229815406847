import { Injectable } from '@angular/core';
import { Badge } from '@capawesome/capacitor-badge';

// import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { DataStoreService } from './data-store.service';
import { HttpCustomService } from './http-custom.service';
import { HttpClient } from '@angular/common/http';

@Injectable()



export class CommonApiService {
  public _hostName = "https://api.testat-app.com/app-api-v11/";
  public _ImageUrl = "https://api.testat-app.com/uploads/";
  public _ImageUrlQuestionsAttachments =
    "https://api.testat-app.com/uploads/questions_attachments/";
  public testatSiteUrl = "https://testat-app.com/";
  public _cronJob = "https://api.testat-app.com/api-const/";
  public _hostNamePrint = "https://api.testat-app.com/print/";
  public fawryUrl =
    "https://api.testat-app.com/api-const-new/fawry-redirect.php?action=";
  public _meetingUrl = "https://testat-app.com/meeting/";
  private _UrlUsers: string = this._hostName + "users.php?action=";

  //server
  public isOnline: boolean = true
  public loaderLang: boolean = false
  constructor(
    public dataStoreService: DataStoreService,
    public httpCustomService: HttpCustomService,
    private http: HttpClient
    // public badge: Badge,
  ) {

  }

  getUsableApi() {
    this.getCountries()
    this.getAllPages()
    this.getAllAdvantages()
    this.getAllExplanations()
  }


  getUserLoginApiData() {
    if (this.dataStoreService.userInfo.id) {
      // this.getAccountData();
      this.getUnReadNotificationNum()
      if (this.dataStoreService.userInfo.user_type != this.dataStoreService.USERTYPES.parent) {
        this.getMySubjects()
        this.getMyGroupsByParams()
      }
    }
  }


  async getAccountData() {

    
    return await new Promise(resolve => {

      this.httpCustomService.get('users.php?action=getUserInfo',)
        .subscribe((res: any) => {
          if (res.success) {
            this.dataStoreService.setUserData(res.data)

            resolve(true)
          } else {
            resolve(false)
            let publishData = { 'event_type': 'Unauthenticated' }
            this.dataStoreService.publishAppComponent(publishData);
          } 
        }, err => {
          resolve(false)
        }
        )
    });

  }
  checkFawryPaymentStauts() {
    this.httpCustomService.getConist('fawry-redirect.php?action=checkFawryPaymentStauts', { 'user_id': this.dataStoreService.userInfo.id })
      .subscribe((res: any) => {
      })
  }


  // getSettingsData() {
  //   if (this.userInfo.id) {
  //     this.httpCustomService.get('store/setting')
  //       .subscribe((ret: any) => {
  //         this.settings = ret.setting
  //       })
  //   }
  // }

  async getAllStages(country_id) {
    // console.log("geocodeUrl",geocodeUrl)
    return await new Promise(resolve => {
      this.httpCustomService.get('common.php?action=getAllStages&country_id=' + country_id).subscribe((res: any) => {
        resolve(res.data)
      }, err => {
        resolve([])
      }
      );
    });
  }

  async getAllGradesWithSpecialize(stage_id) {
    return await new Promise(resolve => {
      this.httpCustomService.get('common.php?action=getAllGradesWithSpecialize&stage_id=' + stage_id).subscribe((res: any) => {
        resolve(res.data)
      }, err => {
        resolve([])
      }
      );
    });
  }

  async getSubjectsByParams(params) {
    return await new Promise(resolve => {
      this.httpCustomService.get('subjects.php?action=getSubjectsByParams', params).subscribe((res: any) => {
        resolve(res.data)
      }, err => {
        resolve([])
      }
      );
    });
  }

  getUserInfo() {
    return this.http.get<any>(this._UrlUsers + "getUserInfo");
  }


  async getMySubjects() {
    this.httpCustomService.get('subjects.php?action=getMySubjects').subscribe((res: any) => {
      if (res.success) {
        if (this.dataStoreService.userInfo.user_type == this.dataStoreService.USERTYPES.student) {
          this.dataStoreService.student_subjects = res.data
        }
        if (this.dataStoreService.userInfo.user_type == this.dataStoreService.USERTYPES.teacher) {
          this.dataStoreService.teacher_subjects = res.data
        }
      } else {
        if (this.dataStoreService.userInfo.user_type == this.dataStoreService.USERTYPES.student) {
          this.dataStoreService.student_subjects = []
        }
        if (this.dataStoreService.userInfo.user_type == this.dataStoreService.USERTYPES.teacher) {
          this.dataStoreService.teacher_subjects = []
        }
      }
    }
    );
  }
  async getMyGroupsByParams() {
    this.httpCustomService.get('groups.php?action=getMyGroupsByParams').subscribe((res: any) => {
      if (res.success) {
        this.dataStoreService.groups = res.data.groups
      }
    }
    );
  }

  async getCountries() {
    return await new Promise(resolve => {
      this.httpCustomService.get('common.php?action=getAllCountries').subscribe((res: any) => {
        this.dataStoreService.countries = res.data
        resolve(res.data)
      }, err => {
        resolve([])
      }
      );
    });
  }

  getAllPages() {
    this.httpCustomService.get('pages.php?action=getAllPages&show_on_mobile=1').subscribe((res: any) => {
      if (res) {
        this.dataStoreService.pages = res.data
      }
    })

  }

  getAllAdvantages() {
    this.httpCustomService.get('common.php?action=getAllAdvantages').subscribe((res: any) => {
      if (res) {
        this.dataStoreService.advantages = res.data
      }
    })
  }
  getAllExplanations() {
    this.httpCustomService.get('common.php?action=getAllExplanations').subscribe((res: any) => {
      if (res) {
        this.dataStoreService.explanations = res.data
      }
    })
  }
  async getAllSettings() {

    return await new Promise(resolve => {

      this.httpCustomService.get('common.php?action=getAllSettings').subscribe((res: any) => {
        if (res) {
          this.dataStoreService.settings = res.data
          resolve(res.data)
        }
      })
    });
  }



  async getUnReadNotificationNum() {

    const set = async (count: number) => {
      await Badge.set({ count });
    };
    const clear = async () => {
      await Badge.clear();
    };
    // code...
    this.httpCustomService.get('notifications.php?action=getUnReadNotificationNum', {})
      .subscribe((res: any) => {
        if (res) {
          if (res.data > 0) {
            this.dataStoreService.unReadNotificationNum = res.data
            // this.badge.set(res.data);
            set(this.dataStoreService.unReadNotificationNum)
          } else {
            this.dataStoreService.unReadNotificationNum = res.data
            // this.badge.clear();
            clear()
          }
        }
        else {
          this.dataStoreService.unReadNotificationNum = 0;
          // this.badge.clear();
          clear()
        }
      }, err => {
        // this.getUnReadNotificationNum(user_id)
        console.log(err)
      });
  }

  // const get = async () => {
  //   const result = await Badge.get();
  //   return result.count;
  // };



  // const increase = async () => {
  //   await Badge.increase();
  // };

  // const decrease = async () => {
  //   await Badge.decrease();
  // };

  // const clear = async () => {
  //   await Badge.clear();
  // };

  // const isSupported = async () => {
  //   const result = await Badge.isSupported();
  //   return result.isSupported;
  // };

  

}


