import { Component, Input, OnInit } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { DataStoreService } from 'src/app/providers/data-store.service';

@Component({
  selector: 'page-display-img',
  templateUrl: './display-img.page.html',
  styleUrls: ['./display-img.page.scss'],
})
export class DisplayImgPage implements OnInit {
  @Input('src') src: string;

  constructor(public modalController: ModalController,
    public dataStoreService: DataStoreService,
  ) {
  }

 

  slidesDidLoad(slides: IonSlides) {
    slides.startAutoplay();
  }
  ngOnInit() {
   this.src =  this.src.replace('/thumbs/', '/')
    console.log(this.src)
  }
  close() {
    this.modalController.dismiss();
  }

}
