import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams, NavController } from '@ionic/angular';

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.page.html',
  styleUrls: ['./custom-alert.page.scss'],
})
export class CustomAlertPage {
  message: any;
  img: any;
  html: any;
  show_back: any;

  constructor(private popoverController: PopoverController, private navParams: NavParams, private navController: NavController) { }

  ngOnInit() {
    this.message = this.navParams.get('message');
    this.img = './assets/images/' + this.navParams.get('img');
    this.html = this.navParams.get('html');
    this.show_back = this.navParams.get('show_back');

    console.log(this.message,
      this.img,
      this.html)
  }
  closeAlert(type: any = '') {
    if (type == 'back') {
      this.navController.back()
    }
    this.popoverController.dismiss();
  }


}

