import { Injectable, Inject } from "@angular/core";
import {
  NavController,
  ToastController,
  ModalController,
  Platform,
  AlertController,
  PopoverController,
  LoadingController,
  IonRouterOutlet,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { CustomAlertPage } from "src/app/pages/common-pages/custom-alert/custom-alert.page";
import { DisplayImgPage } from "../pages/common-pages/display-img/display-img.page";
import * as bcrypt from "bcryptjs";
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';



@Injectable()
export class MyAppUtilfunsService {
  //server

  ArLang: any;
  EnLang: any;
  errorMessage: string;

  loading: any;
  platfrom_mobile: string;
  statusBarHeight: number = 0;
  currentUrl!:any;
  previousUrl!:any;
  

  constructor(
    @Inject("androidLinKApp") public androidLinKApp,
    @Inject("iosLinkApp") public iosLinkApp,
    public navController: NavController,
    public toastController: ToastController,
    public platform: Platform,
    public translateService: TranslateService,
    public popoverController: PopoverController,
    public alertController: AlertController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private router:Router
  ) {
    this.platform.ready().then(() => {
      this.platfrom_mobile = this.platform.is("ios") ? "ios" : "android";
    });
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
    });
  }

  goToMarket() {
    this.platfrom_mobile = this.platform.is("ios") ? "ios" : "android";
    if (this.platfrom_mobile == "ios") {
      if (!window.open(this.iosLinkApp, "_blank")) {
        window.location.href = this.iosLinkApp;
      }
    } else {
      window.open(this.androidLinKApp);
    }
  }

  
  // this.dataStoreService.settings.hide_show_subsecription == '1'

  async showOverLayLoader() {
    this.loading = await this.loadingController.create({
      spinner: "crescent",
      translucent: true,
      cssClass: "custom-class custom-loading",
      backdropDismiss: true,
    });
    await this.loading.present();
  }
  async hideOverLayLoader() {
    setTimeout(() => {
      this.loading.dismiss();
    }, 200);
  }

  async customAlert(message, img = "", html = false, show_back = false) {
    if (!message) {
      return;
    }
    console.log("img", img);
    const popover = await this.popoverController.create({
      component: CustomAlertPage,
      cssClass: "customPopover",
      componentProps: {
        message: message,
        img: img,
        html: html,
        show_back: show_back,
      },
      translucent: false,
      mode: "ios",
    });
    return await popover.present();
  }

  public async AppToast(
    message: string,
    time: any = 6000,
    klass: any = "i",
    locationtost: any = "top"
  ) {
    const toast = await this.toastController.create({
      message: message,
      position: locationtost,
      cssClass: "toast-class-" + klass,
      duration: time,
      buttons: [
        ,
        {
          icon: "close",
          text: "",
          handler: () => { },
        },
      ],
      // closeButtonText: 'Done'
    });
    await toast.present();
  }

  async haveToLogin(message?: string, id?: string) {
    let titletext = this.translateService.instant("L_warning");
    let haveToLogin = message
      ? message
      : this.translateService.instant("L_haveLoginMessage");
    // let filetext  = this.translateService.instant('L_uploade-file');
    let login = this.translateService.instant("L_signIn");
    let alert = await this.alertController.create({
      header: titletext,
      message: haveToLogin,
      buttons: [
        {
          text: login,
          handler: () => {
            this.navController.navigateForward(["login"]);
          },
        },
      ],
    });
    await alert.present(); //used
  }
  async openImageModal(src) {
    const modal = await this.modalController.create({
      component: DisplayImgPage,
      cssClass: "members_modal",
      componentProps: { src: src },
    });
    return await modal.present();
  }

  limitString(txt: string, txtLength: number) {
    if (txt) {
      txt = txt.replace(/<\/?[^>]+(>|$)/g, "");

      if (txt.length > txtLength && txtLength > 0) {
        const str = txt.substring(0, txtLength);
        return str + " ...";
      } else {
        return txt;
      }
    } else {
      return "";
    }
  }
  stringToNumber(number) {
    return +number;
  }
  

  copyToClipboard(val: string, position?:any) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    console.log("val flag => ", val);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    console.log("1 flag");
    //console.log('asdasd')
    document.execCommand("copy");
    console.log("2 flag");
    
    document.body.removeChild(selBox);
    console.log("3 flag");
    if (position == 'top') {
      this.AppToast(
        this.translateService.instant("L_copeDone"),
        400,
        "s",
        "top"
      );
    }else  {
      this.AppToast(
        this.translateService.instant("L_copeDone"),
        400,
        "s",
        "bottom"
      );
    }
  }

  getDateSqlFormat2() {
    let da = new Date();
    let d = da.getDate();
    let m = da.getMonth() + 1;
    let y = da.getFullYear();
    let h = da.getHours();
    let min = da.getMinutes() < 10 ? "0" + da.getMinutes() : da.getMinutes();
    let s = da.getSeconds();
    let fullDate = y + "/" + m + "/" + d + " " + h + ":" + min; //today date like 2018/2/3
    return fullDate;
  }

  getDateSqlFormat() {
    let da = new Date();
    let d = da.getDate();
    let m = da.getMonth() + 1;
    let y = da.getFullYear();
    let h = da.getHours();
    let min = da.getMinutes() < 10 ? "0" + da.getMinutes() : da.getMinutes();
    let s = da.getSeconds();
    let fullDate = y + "/" + m + "/" + d + " " + h + ":" + min + ":" + s; //today date like 2018/2/3
    return fullDate;
  }

  getTimetoSetSqlFormat(date) {
    let da = new Date(date);
    let h = da.getHours();
    let min = da.getMinutes() < 10 ? "0" + da.getMinutes() : da.getMinutes();

    let fullDate = h + ":" + min;
    return fullDate;
  }
  getDateTimetoSetSqlFormat(date) {
    let da = new Date(date);
    let y: any = da.getFullYear();
    let d: any = da.getDate();
    let m: any = da.getMonth() + 1;
    let h: any = da.getHours();
    let min: any = da.getMinutes();
    let s: any = da.getSeconds();
    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }
    if (h < 10) {
      h = "0" + h;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (s < 10) {
      s = "0" + s;
    }
    let fullDate = y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
    return fullDate;
  }
  getDateTimeSqlFormatIOS(date) {
    if (!date) {
      return;
    }
    date = this.getDateTimetoSetSqlFormat(date);
    let arr: any = date.split(/[- :]/);
    let d = arr[0];
    let m = arr[1];
    let y = arr[2];
    let h = arr[3];
    let min = arr[4];
    let P_A = "";
    // console.log('h', h, "min", min)
    if (h >= 12) {
      P_A = this.translateService.instant("L_PM");
    } else {
      P_A = this.translateService.instant("L_AM");
    }
    let h_ = h > 12 ? h - 12 : h == 0 ? 12 : h;
    let fullDate = y + "/" + m + "/" + d + " " + h_ + ":" + min + " " + P_A; //today date like 2018/2/3
    return fullDate;
  }
  getDateSqlFormatIOS2(date) {
    if (!date) {
      return;
    }
    let arr: any = date.split(/[- :]/);
    let d = arr[0];
    let m = arr[1];
    let y = arr[2];
    // console.log('h', h, "min", min)
    let fullDate = y + "/" + m + "/" + d; //today date like 2018/2/3
    return fullDate;
  }
  getDateTimeSqlFormatIOS2(date) {
    if (!date) {
      return;
    }
    let arr: any = date.split(/[- :]/);
    let d = arr[0];
    let m = arr[1];
    let y = arr[2];
    let h = arr[3];
    let min = arr[4];
    let P_A = "";
    // console.log('h', h, "min", min)
    if (h >= 12) {
      P_A = this.translateService.instant("L_PM");
    } else {
      P_A = this.translateService.instant("L_AM");
    }
    let h_ = h > 12 ? h - 12 : h == 0 ? 12 : h;
    let fullDate = y + "/" + m + "/" + d + " " + h_ + ":" + min + " " + P_A; //today date like 2018/2/3
    return fullDate;
  }

  getDateTimetoSetFormat2(date) {
    if (!date) {
      return;
    }
    let da = new Date(date);
    let d = da.getDate();
    let m = da.getMonth() + 1;
    let y = da.getFullYear();
    let fullDate = y + "-" + m + "-" + d;
    return fullDate;
  }

  getDateTimetoSetFormat4() {
    let da = new Date();
    let d: any = da.getDate();
    let m: any = da.getMonth() + 1;
    let y = da.getFullYear();
    let hou=da.getHours()
    let min=da.getMinutes()
    let sec=da.getSeconds()

    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }

    let fullDate = y + "-" + m + "-" + d + " " + hou+":"+min+":"+sec;
    return fullDate;
  }

  getDateTimetoSetFormat3(date) {
    if (!date) {
      return;
    }
    let da = new Date(date);
    let d = da.getDate();
    let m = da.getMonth() + 1;
    let y = da.getFullYear();
    let fullDate = d + "-" + m + "-" + y;
    return fullDate;
  }

  dateDiffInDays(a, b) {
    let _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  englishNumbersFormat(str) {
    if (str) {
      str = "" + str;
      let persianNumbers = [
        /۰/g,
        /۱/g,
        /۲/g,
        /۳/g,
        /۴/g,
        /۵/g,
        /۶/g,
        /۷/g,
        /۸/g,
        /۹/g,
      ],
        arabicNumbers = [
          /٠/g,
          /١/g,
          /٢/g,
          /٣/g,
          /٤/g,
          /٥/g,
          /٦/g,
          /٧/g,
          /٨/g,
          /٩/g,
        ];
      for (var i = 0; i < 10; i++) {
        // console.log(persianNumbers[i], arabicNumbers[i])
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
      return str;
    } else {
      return "";
    }
  }

  getArabicNumber(number) {
    var charIndex = 0;
    var NumericArabic = "";

    while (charIndex < number.length) {
      switch (number[charIndex]) {
        case ".":
          NumericArabic += ".";
          break;

        case "0":
          NumericArabic += "٠";
          break;

        case "1":
          NumericArabic += "١";
          break;

        case "2":
          NumericArabic += "٢";
          break;

        case "3":
          NumericArabic += "٣";
          break;

        case "4":
          NumericArabic += "٤";
          break;

        case "5":
          NumericArabic += "٥";
          break;

        case "6":
          NumericArabic += "٦";
          break;

        case "7":
          NumericArabic += "٧";
          break;

        case "8":
          NumericArabic += "٨";
          break;

        case "9":
          NumericArabic += "٩";
          break;

        default:
          NumericArabic += number[charIndex];
          break;
      }

      charIndex++;
    }

    return NumericArabic;
  }

  removeTagHtml(data) {
    if (!data) {
      return "";
    }
    return data.replace(/<\/?[^>]+(>|$)/g, "");
  }
  

  
  goToBack() {

    // this.navController.back();  
    
    // if (this.currentUrl == "/tabs/teacher-profile/teacher-video") {
    //   this.navController.navigateRoot(['/tabs/teacher-profile/teacher-courses']);
    // } 
    // else if (this.currentUrl == "/tabs/teacher-profile/teacher-courses") {
    //   this.navController.navigateRoot(['/tabs/teacher-profile/ex-teacher']);
    // }
    // else if (this.currentUrl == "/tabs/teacher-profile/ex-teacher") {
    //   this.navController.navigateRoot(['/tabs']);
    // }
    // else {
    //   this.navController.back();
    // }


    this.navController.back();
  } 

  encode(str) {
    str = str + "5R6XD*C$";
    str = bcrypt.hashSync(str);
    str = btoa(str);
    return str;
  }
  encodeStr(str) {
    str = str + "5R6XD*C$";
    // str = bcrypt.hashSync(str);
    str = btoa(str);
    return str;
  }

  ///////////////////////// ****************************************** ///////////////////////////
  /////////////////////////             Routin  -   app                ///////////////////
  ///////////////////////// ****************************************** ///////////////////////////
  // by ahmadklsany
}
