import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { DataStoreService } from 'src/app/providers/data-store.service';
import * as moment from 'moment';

import { MyAppUtilfunsService } from 'src/app/providers/my-app-utilfuns.service';
@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.page.html',
  styleUrls: ['./date-time-picker.page.scss'],
})
export class DateTimePickerPage {
  @Input('data') data: any;
  @Input('type') type: any;
  min: string;


  constructor(public popoverController: PopoverController ,
    private navParams: NavParams,
    public myAppUtilfunsService: MyAppUtilfunsService,
    public dataStoreService: DataStoreService
  ) {
    let min = this.myAppUtilfunsService.getDateSqlFormat()
    this.min = moment(min).format("YYYY-MM-DDTHH:mm:ss")
  }

  closeAlert() {
    let dec = moment(this.data).format("YYYY/MM/DD HH:mm:ss");
    this.popoverController.dismiss(dec);
  }
  changeValue(data) {
    this.data = data.el.value
  }
}
